.menu-button {
  padding: 8px 16px;
  font-size: 14px;
  background-color: #ffffff;
  color: #2A2A2A;
  border: 1.5px solid #E0E0E0;
  border-radius: 6px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.menu-button:hover {
  background-color: #F8F9FA;
  border-color: #D0D0D0;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.menu-button:active {
  transform: translateY(0);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.menu-button.primary {
  background-color: #3B82F6;
  color: white;
  border-color: #2563EB;
}

.menu-button.primary:hover {
  background-color: #2563EB;
  border-color: #1D4ED8;
}

.menu-button.logged-in {
  background-color: #6366F1;
  color: white;
  border-color: #4F46E5;
}

.menu-button.logged-in:hover {
  background-color: #4F46E5;
  border-color: #4338CA;
}

.menu-button.with-arrow {
  background-color: #10B981;
  color: white;
  border-color: #059669;
}

.menu-button.with-arrow:hover {
  background-color: #059669;
  border-color: #047857;
}

.menu-button[onclick*="handleExport"] {
  background-color: #8B5CF6;
  color: white;
  border-color: #7C3AED;
}

.menu-button[onclick*="handleExport"]:hover {
  background-color: #7C3AED;
  border-color: #6D28D9;
}

.menu-button[onclick*="handleAutoLayout"] {
  background-color: #F43F5E;
  color: white;
  border-color: #E11D48;
}

.menu-button[onclick*="handleAutoLayout"]:hover {
  background-color: #E11D48;
  border-color: #BE123C;
}

.desktop-submenu {
  border: 1.5px solid #E0E0E0;
  border-radius: 8px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  padding: 6px 0;
}

.desktop-submenu-item {
  padding: 10px 16px;
  transition: all 0.2s ease;
  color: #4B5563;
}

.desktop-submenu-item:hover {
  background-color: #F3F4F6;
  color: #1F2937;
}

.mobile-menu-item {
  display: block;
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  text-align: left;
  background: none;
  border: none;
  font-size: 14px;
  cursor: pointer;
  color: black;
  text-decoration: none;
}

.mobile-menu-item:hover {
  background-color: #f5f5f5;
}

@media (max-width: 768px) {
  .menu-button {
    display: none;
  }
}

.mobile-submenu-container {
  position: relative;
}

.mobile-menu-item.with-arrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: left;
}

.mobile-submenu {
  padding-left: 15px;
  margin-top: 5px;
}

.mobile-submenu-item {
  display: block;
  width: calc(100% - 15px);
  padding: 8px 10px;
  margin: 2px 0;
  text-align: left;
  background: none;
  border: none;
  font-size: 13px;
  cursor: pointer;
  color: #666;
}

.mobile-submenu-item:hover {
  background-color: #f5f5f5;
  color: #000;
}

.mobile-menu-item {
  display: block;
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  text-align: left;
  background: none;
  border: none;
  font-size: 14px;
  cursor: pointer;
  color: black;
  text-decoration: none;
  transition: background-color 0.2s;
}

.mobile-menu-item:hover {
  background-color: #f5f5f5;
}

.desktop-submenu-container {
  position: relative;
  display: inline-block;
}

.menu-button.with-arrow {
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;
  z-index: 1001;
}

.desktop-submenu {
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  min-width: 200px;
  z-index: 1002;
  padding: 5px 0;
}

.desktop-submenu-item {
  display: block;
  width: 100%;
  padding: 8px 16px;
  text-align: left;
  background: none;
  border: none;
  font-size: 14px;
  cursor: pointer;
  color: black;
  transition: background-color 0.2s;
}

.desktop-submenu-item:hover {
  background-color: #f5f5f5;
}

/* Hamburger menu styles */
.hamburger-button {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  position: relative;
}

.hamburger-line {
  width: 24px;
  height: 3px;
  background-color: black;
  display: block;
  transition: all 0.3s ease;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

/* Position the lines vertically */
.hamburger-line:first-child {
  top: 8px;
}

.hamburger-line:nth-child(2) {
  top: 50%;
  transform: translate(-50%, -50%);
}

.hamburger-line:last-child {
  bottom: 8px;
}

/* Animation for open state */
.hamburger-button.open .hamburger-line:first-child {
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}

.hamburger-button.open .hamburger-line:nth-child(2) {
  opacity: 0;
}

.hamburger-button.open .hamburger-line:last-child {
  top: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
}

/* Update file upload styles */
.custom-file-upload {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

.custom-file-upload input[type="file"] {
  position: absolute;
  left: -9999px;
}

.custom-file-label {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  font-size: 14px;
  background-color: #FFFFFF;
  color: black;
  border: 1px solid black;
  border-radius: 4px;
  cursor: pointer;
}

.custom-file-label:hover {
  background-color: #f5f5f5;
}

/* Hide desktop version in mobile */
@media (max-width: 768px) {
  .desktop-file-upload {
    display: none;
  }
}

/* Style for mobile menu items */
.custom-file-upload .mobile-menu-item {
  width: 100%;
  text-align: left;
  padding: 10px;
  margin: 5px 0;
  background: none;
  border: none;
  font-size: 14px;
  cursor: pointer;
  color: black;
  display: flex;
  align-items: center;
  gap: 8px;
}

.custom-file-upload .mobile-menu-item:hover {
  background-color: #f5f5f5;
}

/* Keep desktop styles separate */
@media (min-width: 769px) {
  .custom-file-upload {
    display: inline-block;
    width: auto;
  }
  
  .custom-file-label {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    font-size: 14px;
    background-color: #FFFFFF;
    color: black;
    border: 1px solid black;
    border-radius: 4px;
    cursor: pointer;
  }
}