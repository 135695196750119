.morphing-button {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 12px 20px;
    background-color: white;
    color: #666;
    border: 1px solid #ddd;
    border-radius: 20px;
    cursor: pointer;
    z-index: 1000;
    transition: all 0.3s ease;
    width: 500px;
    text-align: left;
    font-size: 14px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.morphing-button:hover {
    border-color: #999;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(2px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
}

.morphing-modal {
    background-color: white;
    padding: 30px;
    border-radius: 12px;
    width: 600px;
    max-width: 90vw;  /* Responsive width */
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
}

.morphing-modal h2 {
    margin: 0;
    color: #333;
    font-size: 1.5rem;
    font-weight: 600;
}

.morphing-modal textarea {
    width: 100%;
    box-sizing: border-box;  /* Prevents extending beyond modal */
    min-height: 200px;
    padding: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    resize: vertical;
    font-size: 14px;
    line-height: 1.5;
    color: #333;
    background-color: #fafafa;
    transition: border-color 0.2s ease, background-color 0.2s ease;
}

.morphing-modal textarea:focus {
    outline: none;
    border-color: #4CAF50;
    background-color: white;
}

.morphing-modal textarea::placeholder {
    color: #999;
}

.modal-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
}

.modal-buttons button {
    padding: 10px 24px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    transition: all 0.2s ease;
}

.modal-buttons button:first-child {
    background-color: #4CAF50;
    color: white;
}

.modal-buttons button:first-child:hover {
    background-color: #45a049;
    box-shadow: 0 2px 8px rgba(76, 175, 80, 0.3);
}

.modal-buttons button:last-child {
    background-color: #f5f5f5;
    color: #666;
}

.modal-buttons button:last-child:hover {
    background-color: #e0e0e0;
}

/* Transition classes */
.morph-enter .morphing-modal {
    opacity: 0;
    transform: scale(0.3);
    /* Adjust the transform-origin to account for the modal's width */
    transform-origin: calc(50% - var(--button-width) / 2)
                     calc(100vh - var(--button-height) - 20px);
}

.morph-enter-active .morphing-modal {
    opacity: 1;
    transform: scale(1);
    transform-origin: center center;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.morph-exit .morphing-modal {
    opacity: 1;
    transform: scale(1);
    transform-origin: center center;
}

.morph-exit-active .morphing-modal {
    opacity: 0;
    transform: scale(0.3);
    /* Match the same transform-origin for consistency */
    transform-origin: calc(50% - var(--button-width) / 2)
                     calc(100vh - var(--button-height) - 20px);
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

/* Overlay transitions */
.morph-enter .modal-overlay {
    opacity: 0;
}

.morph-enter-active .modal-overlay {
    opacity: 1;
    transition: opacity 300ms ease;
}

.morph-exit .modal-overlay {
    opacity: 1;
}

.morph-exit-active .modal-overlay {
    opacity: 0;
    transition: opacity 300ms ease;
}
